import { render, staticRenderFns } from "./Bids.vue?vue&type=template&id=5277f5e4&scoped=true"
import script from "./Bids.vue?vue&type=script&lang=js"
export * from "./Bids.vue?vue&type=script&lang=js"
import style0 from "./Bids.vue?vue&type=style&index=0&id=5277f5e4&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5277f5e4",
  null
  
)

export default component.exports