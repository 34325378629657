<template>    
    <div class="space-y-2 w-68"> 


        <h2 class="ml-3 font-semibold py-4">Management</h2>
        <router-link v-if="!isOpus && !isGenreVenueUser" to="/dashboard" class="hover:bg-indigo-600  hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <HomeIcon /> 
            <span class="ml-2">Dashboard</span>
        </router-link>
		<router-link v-if="isOpus" to="/artist-directory" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
				<MusicNoteIcon />
			<span class="ml-2">Artist directory</span>
			</router-link>
        <router-link v-if="!isGenreVenueUser" to="/venues" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <OfficeBuildingIcon/>
            <span class="ml-2">Venue<span v-if="!isOpus">s</span></span>
        </router-link>


				<template v-if="hasAllUserData">

					<template v-if="hasManageGigsPermission">
						<router-link to="/gigs/add" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<TicketIcon/>
							<span class="ml-2">Add a new gig</span>
						</router-link>
					</template>

					<template v-if="hasViewCalendarPermission">
						<router-link to="/calendar" class="text-indigo-100 hover:bg-indigo-600  group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<CalendarIcon /> 
							<span class="ml-2">Calendar</span>
						</router-link>
					</template>

					<template v-if="hasManageGigsPermission">
						<router-link to="/pending-gigs" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<ClockIcon />
							<span class="ml-2">Pending gigs</span>
						</router-link>
					</template>

					<router-link  v-if="(hasBillingInvoicesPermission || hasManageGigsPermission) && !isOpus" to="/past-gigs" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<ClipboardCheckIcon />
							<span class="ml-2">Completed gigs</span>
					</router-link>
					

					<template v-if="hasBillingInvoicesPermission && !isOpus && (!userData || !userData.email.includes('genremusic'))">
						<router-link to="/invoices" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<CurrencyPoundIcon />
							<span class="ml-2">Billing & invoices</span>
						</router-link>
					</template>

					<template v-if="hasShowMonthlyInvoices && !isGenreVenueUser">
						<router-link to="/monthly-invoices" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
							<CurrencyPoundIcon />
							<span class="ml-2">Monthly invoices</span>
						</router-link>
					</template>

					
				</template>
       

		<div v-if="!isGenreVenueUser">

			<h2 class="ml-3 font-semibold py-4">Tools</h2>

			<template v-if="hasAllUserData && hasManageGigsPermission">
				<router-link to="/gigtemplates" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
						<TemplateIcon />
						<span class="ml-2">Gig templates</span>
				</router-link>
			</template>

			<template>
				<router-link to="/requests-to-play" class="text-indigo-100 hover:bg-indigo-600 group hidden items-center px-2 py-2 text-sm font-medium rounded-md">
					<UserAddIcon/>
					<span class="ml-2">Requests to Play</span>
					<span class="uppercase font-bold text-[10px] ml-[60px] text-white px-2 rounded-full bg-red-500">New</span>
				</router-link>
			</template>
		

			<router-link v-if="!isOpus" to="/artist-directory" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
				<MusicNoteIcon />
			<span class="ml-2">Artist directory</span>
			</router-link>

			<router-link to="/favourites"   class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
				<StarIcon />
			<span class="ml-2">My favourite artists</span>
			</router-link>

			<router-link v-show="isGenre" to="/custom-tags"   class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
				<TagIcon />
				<span class="ml-2">Custom Tags</span>
			</router-link>

			<router-link to="/messages" class="text-indigo-100 hidden hover:bg-indigo-600 group items-center px-2 py-2 text-sm font-medium rounded-md">
				<AnnotationIcon/>
				<span class="ml-2">Messages</span>
			</router-link>

			<router-link to="/house-rules" class="text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
				<ClipboardListIcon/>
				<div class="flex justify-between w-full">
					<span class="ml-2">GigPig house rules</span>
					<span class="uppercase font-bold text-[10px] text-white px-2 mr-8 rounded-full bg-red-500">New</span>
				</div>
			</router-link>

			<a href="#" class="hidden text-indigo-100 hover:bg-indigo-600 group items-center px-2 py-2 text-sm font-medium rounded-md">
				<MailIcon/>
				<span class="ml-2">Email templates</span>
			</a> 

		</div>
        

				
    </div>
</template>

<script>


import { HomeIcon, ClipboardListIcon, CalendarIcon, UserAddIcon, TemplateIcon, TicketIcon, OfficeBuildingIcon, MusicNoteIcon, AnnotationIcon, MailIcon, ClockIcon, CurrencyPoundIcon, ClipboardCheckIcon, StarIcon, TagIcon  } from "@vue-hero-icons/outline";

export default {
    name: "NavEvent",
    components: { HomeIcon, ClipboardListIcon, CalendarIcon, UserAddIcon, TemplateIcon, TicketIcon, OfficeBuildingIcon, MusicNoteIcon, AnnotationIcon, MailIcon, ClockIcon, CurrencyPoundIcon, ClipboardCheckIcon, StarIcon, TagIcon },

	computed:{
		user(){
			return this.$store.getters['user/userData'];  
		},
		hasAllUserData(){
			return this.$store.getters['user/hasAllUserDataLoaded']
		},
		hasManageGigsPermission(){
			return this.$store.getters['user/hasManageGigsPermission']
		},
		hasViewCalendarPermission(){
			return this.$store.getters['user/hasViewCalendarPermission']
		},
		hasBillingInvoicesPermission(){
			return this.$store.getters['user/hasBillingInvoicesPermission']
		},
		hasManageTeamMembersPermission(){
			return this.$store.getters['user/hasManageTeamMembersPermission']
		},
		hasReferralCodeGenre(){
			return this.$store.getters['user/hasReferralCodeGenre']
		},
		hasShowMonthlyInvoices(){
			return this.$store.getters['user/hasShowMonthlyInvoices']
		},
		userData(){
			return this.$store.getters['user/userData']
		},
		isGenre() {
			return this.userData && this.userData.email && this.userData.email.includes('genremusic')
		},
		isOpus() {
			return this.userData && this.userData.is_opus == true;
		},
		isGenreVenueUser(){
			return this.userData && this.userData.is_genre_venue == true;
		}
	}

}
</script>