<template>

  <div  transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">   
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 transition-opacity" aria-hidden="true"></div>


      <div :class="artist_only || venue_only ? 'sm:max-w-md': 'sm:max-w-2xl'" class="inline-block align-bottom bg-[#2e234e] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6">
        <div>
          <div class="mx-auto flex flex-col gap-4   items-center justify-center brandedImage">
            <img src="https://gigpigcdn.ams3.cdn.digitaloceanspaces.com/branding/OpusApeiro_Logo_Landscape_Web_FullColourWhiteText.svg" alt="OpusApeiro" class="max-w-[200px]" />
            <h1 class="font-normal text-xl text-white text-center">Please use your c247 dashboard to access your venue via the 'entertainment' tab</h1>
          </div>
 
        </div>
      </div>

    </div>
  </div> 


</template>

<script> 
export default {
  name: "GigPig",
  props:{
    artist_only: Boolean,
    venue_only: Boolean,
    allowVenues: {type: Boolean, default: true},
    alignment: {type: String, default: 'justify-center'}
  },
}
</script>

<style scoped>

.external .router-link-active {

  @apply bg-transparent;

}
.fadeIn-transition {
  transition: all .3s ease;
  opacity:0;
}
.expand-enter, .expand-leave {
  opacity: 1;
}
</style>